import { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LayoutBase from 'components/Layout';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import auth from 'services/auth';
import throwException from 'utils/throwException';
import { getLocalStogareKey } from 'utils';
import { Spin } from 'antd';
import routes, { IRoute } from 'routes';

const history = createBrowserHistory();

class Pages extends Component<any, any> {
  constructor(props: any) {
    super(props);
    let loading = true;
    const routeInit = routes.find((e) => e.path === history.location.pathname);
    if (routeInit) {
      if (routeInit.auth !== false) {
        if (getLocalStogareKey('access_token')) {
          this.handleFetchData();
        } else if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      } else {
        loading = false;
      }
    } else {
      if (getLocalStogareKey('access_token')) {
        this.handleFetchData();
      } else if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    this.state = { loading };
  }

  shouldComponentUpdate(_, nState: any) {
    const { loading } = this.state;
    return loading !== nState.loading;
  }

  handleFetchData = () => {
    auth
      .getUser()
      .then(({ data }) => {
        const { dispatch } = this.props;
        if (['/login', '/'].includes(window.location.pathname)) {
          window.location.href = '/account-management';
        } else {
          dispatch({ type: 'user/change', payload: { user: data?.data } });
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <Spin
          spinning
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }
    return (
      <BrowserRouter>
        <LayoutBase history={history}>
          <Switch>
            {routes.reduce((currentRoutes: any[], route: IRoute, index: number) => {
              const { menuLeft } = route;
              if (menuLeft) {
                if (Array.isArray(menuLeft)) {
                  menuLeft.forEach((ee: any) => {
                    ee.routes.forEach((menu: any, i: number) => {
                      const Component = menu.component;
                      if (Component) {
                        currentRoutes.push(
                          <Route
                            path={menu.path}
                            key={(i + index).toString()}
                            exact={menu.exact}
                            component={(props) => <Component {...props} pathname={menu.path} />}
                          />
                        );
                      }
                    });
                  });
                } else {
                  menuLeft.routes.forEach((menu: any, i: number) => {
                    const Component = menu.component;
                    if (menu.component) {
                      currentRoutes.push(
                        <Route
                          path={menu.path}
                          key={(i + index).toString()}
                          exact={menu.exact}
                          component={(props) => <Component {...props} pathname={menu.path} />}
                        />
                      );
                    }
                  });
                }
              } else {
                const Component = route.component;
                if (Component) {
                  currentRoutes.push(
                    <Route
                      path={route.path}
                      key={index.toString()}
                      exact={route.exact}
                      component={(props) => <Component {...props} pathname={route.path} />}
                    />
                  );
                }
              }
              return currentRoutes;
            }, [])}
            <Redirect from="*" to="/404" />
          </Switch>
        </LayoutBase>
      </BrowserRouter>
    );
  }
}

export default connect()(withTranslation()(Pages));
