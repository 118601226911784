import { Button, Table, DatePicker, Select } from 'antd';
import { TableProps } from 'antd/lib/table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { Component, FC } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import ModalCreate from './ModalAdd';
import debounce from 'lodash/debounce';
import './styles.scss';
import EmptyBase from 'components/Empty';
import InputBase from 'components/Input';
import _ from 'lodash';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

interface IProps {
  allowAdd?: boolean;
  lableKeyI18n?: string;
  t: any;
  formAdd?: (v?: any) => FC | JSX.Element;
  widthModalAdd?: number | string;
  onOkFormAdd?: (v?: any, callback?: any) => any;
  handleSearch?: (v: string) => any;
  style?: any;
  resetSearch?: (v: any) => any;
  handleSearchOption?: (v: string) => any;
  handleSearchDateTime?: (v: string) => any;
  dataOption?: any[];
  formAddhiddenFooter?: boolean;
  okText?: string;
  cancelText?: string;
  typeSearch?: string;
  disabledAdd?: boolean;
  placeholderInputSearch?: string;
  showSearch?: boolean;
}

interface IState {
  valueSearch: string;
}

class TableBase extends Component<IProps & TableProps<DefaultRecordType>, IState> {
  modalCreate: any;

  constructor(props: IProps & TableProps<DefaultRecordType>) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
    this.state = { valueSearch: '' };
    const { resetSearch } = props;
    if (typeof resetSearch === 'function') {
      resetSearch(() => {
        this.setState({ valueSearch: '' });
      });
    }
  }
  componentWillUnmount() {
    const { resetSearch } = this.props;
    if (typeof resetSearch === 'function') {
      resetSearch(undefined);
    }
  }

  handleModalCreate = () => {
    const { disabledAdd } = this.props;
    if (disabledAdd) {
      return;
    }
    this.modalCreate?.handleVisible?.();
  };

  handleSearch = (e) => {
    const { handleSearch } = this.props;
    if (handleSearch) handleSearch(e);
  };

  handleSearchOption = (e) => {
    const { handleSearchOption } = this.props;
    if (handleSearchOption) handleSearchOption(e);
  };

  handleSearchDateTime = (e) => {
    const { handleSearchDateTime } = this.props;
    if (handleSearchDateTime) handleSearchDateTime(e);
  };

  handleOke = (values, callback) => {
    const { onOkFormAdd } = this.props;
    onOkFormAdd?.(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      this.setState({ valueSearch: '' });
    });
  };

  getPlaceHolder = (type) => {
    const { t } = this.props;
    switch (type) {
      case 'email':
        return t('history.log.email_search.placeholder');
      case 'phone':
        return t('history.log.phone_number_search.placeholder');
      default:
        return t('table_base.input.search.placeholder');
    }
  };

  render() {
    const {
      dataOption,
      handleSearchDateTime,
      handleSearchOption,
      columns,
      dataSource,
      rowKey,
      formAdd,
      widthModalAdd,
      onOkFormAdd,
      handleSearch,
      t,
      lableKeyI18n,
      allowAdd,
      style,
      pagination,
      scroll,
      okText,
      cancelText,
      typeSearch,
      disabledAdd,
      placeholderInputSearch,
      ...props
    } = this.props;
    const { valueSearch } = this.state;
    return (
      <div className="table-base" style={{ margin: 16, ...style }}>
        {handleSearch && (
          <div style={{ display: 'flex' }}>
            <InputBase
              onChange={(e) => {
                this.setState({ valueSearch: e.target.value });
                this.handleSearch(e);
              }}
              style={{ width: 400, marginBottom: 12 }}
              prefix={<SearchOutlined />}
              placeholder={placeholderInputSearch || this.getPlaceHolder(typeSearch)}
              value={valueSearch}
            />
            {handleSearchOption && (
              <Select
                showSearch={!_.isEmpty(this.props.showSearch) ? true : this.props.showSearch}
                optionFilterProp="children"
                onChange={(e) => this.handleSearchOption(e)}
                style={{
                  width: 400,
                  marginBottom: 12,
                  height: 42,
                  marginLeft: 12,
                  borderRadius: 4,
                }}
                defaultValue={''}
              >
                {dataOption?.length &&
                  dataOption.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.text}
                      </Option>
                    );
                  })}
              </Select>
            )}
            {handleSearchDateTime && (
              <RangePicker
                format={dateFormat}
                placeholder={[t('field.date.placeholder'), t('field.date.placeholder')]}
                onChange={(date, dateString) => {
                  this.handleSearchDateTime(dateString);
                }}
                style={{
                  width: 400,
                  marginBottom: 12,
                  height: 42,
                  marginLeft: 12,
                  borderRadius: 4,
                }}
              />
            )}
          </div>
        )}
        <Table
          scroll={scroll || { x: 1334 }}
          locale={{ emptyText: <EmptyBase /> }}
          {...props}
          rowKey={rowKey}
          dataSource={dataSource}
          columns={columns}
          pagination={{ ...pagination, showSizeChanger: false }}
        />
        <ModalCreate
          okText={okText}
          cancelText={cancelText}
          widthModalAdd={widthModalAdd}
          onRef={(ref) => {
            this.modalCreate = ref;
          }}
          content={formAdd}
          onOk={this.handleOke}
        />
        {allowAdd ? (
          <div className="button-add" style={{ top: handleSearch ? 58 : 5 }}>
            <Button
              disabled={!!disabledAdd}
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.handleModalCreate}
            >
              {t(lableKeyI18n)}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(TableBase);
