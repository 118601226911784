import { Form, Spin, Steps } from 'antd';
import InputBase from 'components/Input';
import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { validateEmail } from 'utils';
import throwException from 'utils/throwException';
import { pdfjs } from 'react-pdf';
import upload from 'services/upload';
import otherSetting from 'services/ortherSetting';
import UploadFile from 'Pages/ContentManagement/Notice/FormAdd/UploadFile';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  t: any;
  form: any;
  onRef?: (r: any) => any;
  i18n: any;
  device_app: 'MOBILE' | 'ADMIN_SYS' | 'WEB_APP' | 'SUPPORT';
  type: 'TERM' | 'PRIVACY' | 'URL_SUPPORT';
  disabled?: boolean;
  handleRemapOkText?: (v?: any) => any;
}

interface IState {
  loading: boolean;
  step: number;
  valuesSave: any;
}

class FormAdd extends Component<IProps, IState> {
  uploadFile: any;
  constructor(props: IProps) {
    super(props);
    this.state = { loading: false, step: 0, valuesSave: {} };
  }

  async componentDidMount() {
    const { disabled, form } = this.props;
    const file_url = form.getFieldValue('file_url');
    if (disabled) {
      const { data } = await this.handleGetUrl(file_url);
      if (data?.[0]?.url) {
        this.renderData(data[0]?.url);
      }
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleGetUrl = async (files: any[] = []) => {
    if (!Array.isArray(files) || !files.length) return {};
    try {
      const { data } = await upload.preview({ files: files.map((e) => e.fileKey) });
      return data;
    } catch (e) {
      throwException(e);
      return {};
    }
  };

  handleOk = async (values, callback) => {
    const { step } = this.state;
    const { form, handleRemapOkText, type, device_app, t } = this.props;
    const file_url = form.getFieldValue('file_url');
    if (!file_url?.length) {
      callback(false);
      this.uploadFile?.setError(t('upload.file.required'));
      return;
    }
    this.uploadFile?.setError();
    if (!step) {
      callback(false);
      const { data } = await this.handleGetUrl(file_url);
      if (typeof handleRemapOkText === 'function') {
        handleRemapOkText(t('other_setitng.modal.publish.preview'));
      }
      this.setState({ step: 1, valuesSave: values }, () => {
        if (data?.[0]?.url) {
          this.renderData(data[0]?.url);
        }
      });
    } else {
      const { valuesSave } = this.state;
      otherSetting
        .create({ ...valuesSave, type, device_app: device_app, file_url })
        .then(() => {
          if (callback) callback(true);
        })
        .catch((e) => {
          throwException(e);
          if (callback) callback(false);
        });
    }
  };

  renderPage = async ({ pdfDoc, scale }) => {
    const totalPage = pdfDoc._pdfInfo.numPages;
    const element = document.getElementById('element_canvas_append');
    if (!element) return;
    element.innerHTML = '';
    for (let i = 1; i <= totalPage; i++) {
      if (i === 3 || i === totalPage) {
        this.setState({ loading: false });
      }
      const canvas = document.createElement('canvas');
      const page = await pdfDoc.getPage(i);
      const ctx = canvas.getContext('2d');
      const viewport = page.getViewport({ scale });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      canvas.style.width = '100%';
      element.append(canvas);
      page.render({
        canvasContext: ctx,
        viewport: viewport,
      });
    }
  };

  renderData = async (file) => {
    this.setState({ loading: true });
    const loadingTask = pdfjs.getDocument(file);
    const pdfDoc = await loadingTask.promise;
    await this.renderPage({ pdfDoc, scale: 3 });
  };

  renderTitle = () => {
    const { type, device_app, t } = this.props;
    if (type === 'TERM' && device_app === 'ADMIN_SYS') {
      return t('other_setting.admin_system.modal_add_new_term_of_use');
    } else if (type === 'TERM' && device_app === 'MOBILE') {
      return t('other_setting.admin_system.title.modal_term_mobile');
    } else if (type === 'TERM' && device_app === 'WEB_APP') {
      return t('other_setting.admin_system.title.modal_term_webapp');
    } else if (type === 'PRIVACY' && device_app === 'ADMIN_SYS') {
      return t('other_setting.admin_system.title.modal_privacy_admin_sys');
    } else if (type === 'PRIVACY' && device_app === 'WEB_APP') {
      return t('other_setting.admin_system.title.modal_privacy_webapp');
    } else if (type === 'PRIVACY' && device_app === 'MOBILE') {
      return t('other_setting.admin_system.title.modal_privacy_mobile');
    }
    return 'title';
  };

  setLoading = (flag?: boolean) => {
    this.setState({ loading: !!flag });
  };

  render() {
    const { t, onRef, disabled } = this.props;
    if (onRef) onRef(this);
    const { loading, step } = this.state;
    return (
      <Spin spinning={loading}>
        <div className="formAddAccount">
          <div style={{ fontWeight: 600, fontSize: 21, fontFamily: 'Noto Sans CJK JP' }}>
            <div>{this.renderTitle()}</div>
          </div>
          {disabled ? (
            <div
              style={{
                color: '#007AFF',
                fontSize: 17,
                fontWeight: 600,
                borderBottom: '1px solid #DADADA',
                marginBottom: 30,
                marginTop: 34,
                fontFamily: 'Noto Sans CJK JP',
              }}
            >
              {t('other_setitng.modal.add.information')}
            </div>
          ) : (
            <Steps
              size="small"
              current={step}
              style={{ width: 311, margin: 'auto', marginTop: 15, marginBottom: 15 }}
            >
              <Steps.Step title={t('other_setitng.modal.add.information')} />
              <Steps.Step title={t('other_setitng.modal.add.preview')} />
            </Steps>
          )}

          {step === 0 ? (
            <Fragment>
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t('other_setting.table.column.version_id.required'),
                  },
                ]}
                name="version_id"
                label={t('other_setting.table.column.version_id')}
              >
                <InputBase
                  placeholder={t('other_setting.table.column.version_id.placeholder')}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t('content.management.modal.create_new.field.title.required'),
                  },
                ]}
                name="title"
                label={t('content.management.modal.create_new.field.title')}
              >
                <InputBase
                  disabled={disabled}
                  placeholder={t('content.management.modal.create_new.field.title.placeholder')}
                />
              </Form.Item>
              <Form.Item name="memo" label={t('content.management.modal.create_new.field.memo')}>
                <InputBase.TextArea
                  disabled={disabled}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder={t('content.management.modal.create_new.field.memo.placeholder')}
                  maxLength={12000}
                />
              </Form.Item>
            </Fragment>
          ) : null}
          {disabled || step === 1 ? (
            <div>
              {disabled ? (
                <div
                  style={{
                    color: '#007AFF',
                    fontSize: 17,
                    fontWeight: 600,
                    borderBottom: '1px solid #DADADA',
                    marginBottom: 30,
                    marginTop: 34,
                    fontFamily: 'Noto Sans CJK JP',
                  }}
                >
                  {t('other_setitng.modal.add.preview')}
                </div>
              ) : null}
              <div
                style={{
                  textAlign: 'center',
                  background: '#fff',
                  padding: 5,
                  maxHeight: 450,
                  minHeight: 200,
                  overflow: 'auto',
                }}
                id="element_canvas_append"
              />
            </div>
          ) : (
            <Form.Item shouldUpdate>
              {(props: any) => {
                const file_url = props.getFieldValue('file_url');
                return (
                  <UploadFile
                    onRef={(ref) => (this.uploadFile = ref)}
                    multiple={false}
                    setLoading={this.setLoading}
                    file_url={file_url}
                    setFieldsValue={props.setFieldsValue}
                    t={t}
                  />
                );
              }}
            </Form.Item>
          )}
        </div>
      </Spin>
    );
  }
}

export default withTranslation()(FormAdd);
