import { Modal, Form, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import account from 'services/account';
import { showMessageError, showMessageSuccess } from 'utils';
import throwException from 'utils/throwException';
import FormAdd from '../FormAdd';

interface IProps {
  onRef?: any;
  t: any;
  form: any;
  reloadData?: () => any;
}

interface IState {
  visible: boolean;
  isDetail?: boolean;
}

class ModalEdit extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, isDetail: false };
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { visible } = this.state;
    return visible !== nState.visible;
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleVisible = async (record: any, isDetail?: boolean) => {
    const { form } = this.props;
    if (record) {
      const { data } = await account.detail(record._id).then(({ data }) => data);
      const { deleted } = data;
      if (deleted && !isDetail) {
        const { reloadData, t } = this.props;
        showMessageError(t('account.management.message_warning.account_has_deleted'));
        return reloadData?.();
      }
      const {
        contract_end_date,
        contract_start_date,
        service_access_start_date_actual,
        service_access_end_date_actual,
      } = data;
      form.setFieldsValue({
        ...data,
        contract_start_date: contract_start_date ? moment(contract_start_date) : null,
        contract_end_date: contract_end_date ? moment(contract_end_date) : null,
        service_access_start_date_actual: moment(service_access_start_date_actual),
        service_access_end_date_actual: service_access_end_date_actual
          ? moment(service_access_end_date_actual)
          : undefined,
      });
    } else {
      form.resetFields();
    }
    this.setState({ visible: !!record, isDetail });
  };

  handleEdit = (values) => {
    const { reloadData, t, form } = this.props;
    const is_active = form.getFieldValue('is_active');
    const id = form.getFieldValue('_id');
    let v = {};
    Object.keys(values).forEach((key) => {
      v[key] = values[key] || null;
    });
    account
      .update({ ...v, is_active }, id)
      .then(() => {
        this.handleVisible(undefined);
        showMessageSuccess(t('account.management.edit_account.success'));
        if (typeof reloadData === 'function') reloadData();
      })
      .catch(throwException);
  };

  handleTurnOffIP = () => {
    const { reloadData, t, form } = this.props;
    const id = form.getFieldValue('_id');
    account
      .turnOffIP(id)
      .then(() => {
        this.handleVisible(undefined);
        showMessageSuccess(t('account.management.edit_account.success'));
        if (typeof reloadData === 'function') reloadData();
      })
      .catch(throwException);
  };

  onCloseRemove = () => {
    const { reloadData } = this.props;
    this.handleVisible(undefined);
    if (typeof reloadData === 'function') {
      reloadData();
    }
  };

  render() {
    const { visible, isDetail } = this.state;
    const { onRef, t, form, reloadData } = this.props;

    if (onRef) onRef(this);
    return (
      <Modal
        maskClosable={false}
        onCancel={() => {
          this.handleVisible(undefined);
        }}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
        width={791}
      >
        <Form
          form={form}
          colon={false}
          layout="vertical"
          autoComplete="off"
          onFinish={this.handleEdit}
          scrollToFirstError
        >
          <div style={{ padding: 16 }}>
            <FormAdd
              reloadData={reloadData}
              onClose={this.onCloseRemove}
              limitIP={form.getFieldValue('is_limit_ip')}
              form={form}
              isCreate={false}
              isDetail={isDetail}
              data={{ ...form.getFieldsValue(), _id: form.getFieldValue('_id') }}
              handleTurnOffIP={this.handleTurnOffIP}
            />
          </div>
          {isDetail ? (
            <Form.Item
              style={{
                textAlign: 'right',
                margin: 0,
                boxShadow: '0px -1px 0px #EFEFF4',
                padding: '10px 16px',
              }}
            >
              <Button
                onClick={() => this.handleVisible(undefined)}
                style={{ height: 40, marginLeft: 12 }}
              >
                {t('common.button.close')}
              </Button>
            </Form.Item>
          ) : (
            <Form.Item
              style={{
                textAlign: 'right',
                margin: 0,
                boxShadow: '0px -1px 0px #EFEFF4',
                padding: '10px 16px',
              }}
            >
              <Button type="primary" style={{ height: 40 }} htmlType="submit">
                {t('account.management.modal.edit.button.save')}
              </Button>
              <Button
                onClick={() => this.handleVisible(undefined)}
                style={{ height: 40, marginLeft: 12 }}
              >
                {t('account.management.modal.edit.button.cancel')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(ModalEdit));
