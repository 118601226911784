import axiosConfig from 'config/axios';

const cms = {
  async usersCms(dataReq) {
    try {
      const data = await axiosConfig.get('/admin-cms', { params: dataReq });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async createUserCms(dataReq) {
    try {
      const data = await axiosConfig.post('/admin-cms', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async listUserSupport(dataReq) {
    try {
      const data = await axiosConfig.get('/admin-cms/all/user-support', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async updateUser(id: string, dataReq) {
    try {
      const data = await axiosConfig.put(`/admin-cms/${id}`, dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async resetPassword() {
    try {
      const data = await axiosConfig.post(`/admin-cms/reset_password`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getLogUser(id) {
    try {
      const data = await axiosConfig.get(`/admin-cms/log-admin-cms/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default cms;
