import { Form, Spin } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { validateEmail } from 'utils';
import throwException from 'utils/throwException';
import content from 'services/content';
import InputBase from 'components/Input';
import './styles.scss';
import UploadFile from './UploadFile';

interface IProps {
  t: any;
  onRef?: (r: any) => any;
  i18n: any;
  form: any;
  isCreate: boolean;
}

interface IState {
  loading: boolean;
}

class FormAddAccount extends Component<IProps, IState> {
  modalWarning: any;

  constructor(props: IProps) {
    super(props);
    this.state = { loading: false };
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { loading } = this.state;
    return loading !== nState.loading;
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleOk = (values, callback) => {
    const { loading } = this.state;
    if (loading) return;
    const { form } = this.props;
    const file_url = form.getFieldValue('file_url');
    content
      .create({
        ...values,
        content_type: 'NOTICE',
        file_url,
      })
      .then(() => {
        if (callback) callback(true);
      })
      .catch((e) => {
        throwException(e);
        if (callback) callback(false);
      });
  };

  setLoading = (flag: boolean) => {
    this.setState({ loading: flag });
  };

  isValidHttpUrl = (rule, value, callback) => {
    let url;
    const { t } = this.props;
    if (value) {
      try {
        url = new URL(value);
      } catch (_) {
        // throw new Error(t('settingPage.sosSetUp.form.require.url'));
        return Promise.reject(new Error(t('settingPage.sosSetUp.form.require.url')));
      }
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return Promise.reject(new Error(t('settingPage.sosSetUp.form.require.url')));
      }
    }

    return Promise.resolve();
  };

  render() {
    const { loading } = this.state;
    const { t, onRef, isCreate } = this.props;
    if (onRef) onRef(this);
    return (
      <Spin spinning={loading}>
        <div className="formAddAccount">
          <div className="title-modal">
            <div>
              {isCreate
                ? t('content.management.modal.create_new_notice.title')
                : t('content.management.modal.edit_notice.title')}
            </div>
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('content.management.modal.create_new.field.title.required'),
              },
            ]}
            name="title"
            label={t('content.management.modal.create_new.field.title')}
          >
            <InputBase
              maxLength={70}
              placeholder={t('content.management.modal.create_new.field.title.placeholder')}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('content.management.modal.create_new.field.description')}
          >
            <InputBase.TextArea
              autoSize={{ minRows: 6, maxRows: 6 }}
              maxLength={256}
              placeholder={t(
                'content.management.modal.create_new.field.content_description.placeholder'
              )}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: false,
                whitespace: true,
                message: t('content.management.modal.create_new.field.external_URL_link.required'),
              },
              { validator: this.isValidHttpUrl },
            ]}
            name="source_url"
            label={t('content.management.modal.create_new.field.external_URL_link')}
          >
            <InputBase
              placeholder={t(
                'content.management.modal.create_new.field.external_URL_link.placeholder'
              )}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {(props: any) => {
              const file_url = props.getFieldValue('file_url');
              return (
                <UploadFile
                  accepts={['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']}
                  placeholerKey="content.management.modal.create_new.field.upload_file_pdf_image_notice_drag"
                  setLoading={this.setLoading}
                  file_url={file_url}
                  setFieldsValue={props.setFieldsValue}
                  t={t}
                />
              );
            }}
          </Form.Item>
        </div>
      </Spin>
    );
  }
}

export default withTranslation()(FormAddAccount);
