import { Modal, Form, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import content from 'services/content';
import upload from 'services/upload';
import { showMessageSuccess } from 'utils';
import throwException from 'utils/throwException';
import FormAdd from '../FormAdd';

interface IProps {
  onRef?: any;
  t: any;
  form: any;
  reloadData?: () => any;
}

interface IState {
  visible: boolean;
  dataView: any;
  loading: boolean;
}

class ModalEdit extends Component<IProps, IState> {
  formAdd: any;
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, dataView: {}, loading: false };
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { visible } = this.state;
    return visible !== nState.visible;
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleVisible = async (record: any) => {
    const { form } = this.props;
    if (record) {
      const { title, source_url, file_url } = record;
      const { data: dataUrlGet } = await this.handleGetUrl([file_url[0]?.fileKey]);
      form.setFieldsValue({
        title,
        source_url,
        file_url: [
          { ...file_url[0], url_preview: dataUrlGet?.[0]?.url, size: dataUrlGet?.[0]?.size },
        ],
      });
      if (!file_url[0]) {
        form.setFieldsValue({ file_url: null });
      }
    } else {
      form.resetFields();
    }
    this.setState({ visible: !!record, dataView: record || {} });
  };

  handleGetUrl = async (files: any[] = []) => {
    if (!Array.isArray(files) || !files.length || !files[0]) return {};
    try {
      const { data } = await upload.preview({ files });
      return data;
    } catch (e) {
      throwException(e);
      return {};
    }
  };

  handleEdit = (values) => {
    const { loading, dataView } = this.state;
    if (loading) return;
    const { reloadData, t } = this.props;
    const { error, value } = this.formAdd?.validateFile?.();
    if (error) {
      return;
    }
    content
      .edit({ ...values, content_type: 'ARTICLE', file_url: value }, dataView._id)
      .then(() => {
        this.handleVisible(undefined);
        if (reloadData) reloadData();
        showMessageSuccess(t('article.edit.success'));
      })
      .catch((e) => {
        throwException(e);
        this.handleVisible(undefined);
      });
  };

  render() {
    const { visible, loading } = this.state;
    const { onRef, t, form } = this.props;
    if (onRef) onRef(this);
    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
        width={791}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            colon={false}
            layout="vertical"
            autoComplete="off"
            onFinish={this.handleEdit}
            scrollToFirstError
          >
            <div style={{ padding: 16 }}>
              <FormAdd onRef={(ref) => (this.formAdd = ref)} form={form} isCreate={false} />
            </div>
            <Form.Item
              style={{
                textAlign: 'right',
                margin: 0,
                boxShadow: '0px -1px 0px #EFEFF4',
                padding: '10px 16px',
              }}
            >
              <Button type="primary" style={{ height: 40 }} htmlType="submit">
                {t('account.management.modal.edit.button.save')}
              </Button>
              <Button
                onClick={() => this.handleVisible(undefined)}
                style={{ height: 40, marginLeft: 12 }}
              >
                {t('account.management.modal.edit.button.cancel')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(ModalEdit));
