import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import modal from 'antd/lib/modal';
import SwitchBox from 'components/SwitchBox';
import moment from 'moment';
import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import account from 'services/account';
import { showMessageSuccess, validateEmail } from 'utils';
import throwException from 'utils/throwException';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import './styles.scss';
import InputBase from 'components/Input';
import cms from 'services/cms';
import { configModal } from 'components/ModalWarning';

interface IProps {
  t: any;
  onRef?: (r: any) => any;
  i18n: any;
  isCreate?: boolean;
  form: any;
  handleTurnOffIP?: () => any;
  limitIP?: boolean;
  isDetail?: boolean;
  data: any;
  onClose?: any;
  reloadData?: any;
}

interface IState {
  loading: boolean;
  dataUserSupport: any[];
  activeUser: boolean;
}

class FormAddAccount extends Component<IProps, IState> {
  modalWarning: any;
  constructor(props: IProps) {
    super(props);
    const { form } = props;
    const activeUser = form.getFieldValue('is_active');
    this.state = { loading: true, dataUserSupport: [], activeUser };
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(this);
    }
    this.getDataAccountSupport();
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleValidatePhone = (_, v) => {
    const { t } = this.props;
    if (!v) return Promise.resolve();
    if (v.length < 9) {
      return Promise.reject(new Error(t('create.account.phone.format')));
    }
    const regexNumber = /^[0-9]+$/;

    const isNumber = (value: string): boolean => regexNumber.test(value.toLowerCase());

    const checkPhone: boolean = isNumber(v);

    if (!checkPhone) {
      return Promise.reject(new Error(t('create.account.phone.format')));
    }

    return Promise.resolve();
  };

  renderDataType = () => {
    const { t } = this.props;
    return [
      { value: 'EDUCATIONAL', title: t('title.type.account.enterprise') },
      { value: 'ENTERPRISE', title: t('title.type.account.education') },
      { value: 'MEDICAL', title: t('title.type.account.medical') },
    ];
  };

  getDataAccountSupport = () => {
    const { form } = this.props;
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    cms
      .listUserSupport({})
      .then(({ data }) => {
        const sos_support_user = form.getFieldValue('sos_support_user');
        if (!data.data.find((e) => e._id === sos_support_user)) {
          form.setFieldsValue({ sos_support_user: undefined });
        }
        this.setState({ dataUserSupport: data.data, loading: false });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  handleOk = (values, callback) => {
    const { i18n } = this.props;
    const { language } = i18n;
    account
      .create({ ...values, language })
      .then(() => {
        if (callback) callback(true);
      })
      .catch((e) => {
        throwException(e);
        if (callback) callback(false);
      });
  };

  handleTurnOffIP = () => {
    const { handleTurnOffIP } = this.props;
    if (typeof handleTurnOffIP === 'function') handleTurnOffIP();
    this.handleCancelDelete();
  };

  handleCancelDelete = () => {
    this.modalWarning?.destroy();
  };

  handleTurnOffIPModal = (record: any) => {
    const { t } = this.props;
    const config = {
      title: t('account.management.modal.warning_turn_off_ip.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>{t('account.management.modal.warning_turn_off_ip.content')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger
              style={{ marginRight: 12, height: 40 }}
              onClick={this.handleTurnOffIP}
            >
              {t('account.management.modal.warning_turn_off_ip.button.disabled')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelDelete}>
              {t('account.management.modal.warning_turn_off_ip.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarning = modal.confirm(config);
  };

  handleRemove = () => {
    const { data, onClose, t } = this.props;
    this.setState({ loading: true });
    account
      .delete(data._id)
      .then(() => {
        if (onClose) onClose();
        showMessageSuccess(t('account.management.delete_account.success'));
        this.setState({ loading: false });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  handleChangeStatus = (callback, checked) => {
    const { data, t, reloadData } = this.props;
    this.setState({ loading: true });
    account
      .changeStatus(data._id)
      .then(() => {
        if (typeof reloadData === 'function') {
          reloadData();
        }
        if (checked) {
          showMessageSuccess(t('account.management.modal.edit.status.success'));
        } else {
          showMessageSuccess(t('account.management.modal.edit.status.deactive.success'));
        }

        this.setState({ loading: false, activeUser: !!checked });
        if (callback) callback();
      })
      .catch((e) => {
        this.setState({ loading: false });
        throwException(e);
      });
  };

  handleWarningDeActive = (checked: boolean, propsForm: any) => {
    const { t } = this.props;
    if (checked) {
      return this.handleChangeStatus(
        () => propsForm.setFieldsValue({ is_active: !!checked }),
        checked
      );
    }
    configModal.open({
      title: t('account.management.modal.warning.deactive_account.title'),
      i18nOk: 'account.management.table.column.status.value.inactive',
      i18nCancel: 'account.management.modal.warning_turn_off_ip.button.cancel',
      width: 791,
      buttonOkProps: {
        danger: true,
        style: { width: 108, height: 40 },
      },
      content: (
        <div style={{ height: 180 }}>
          {t('account.management.modal.warning.deactive_account.content')}
        </div>
      ),
      onOk: () => {
        this.handleChangeStatus(() => {
          configModal.close();
          propsForm.setFieldsValue({ is_active: !!checked });
        }, checked);
      },
      onCancle: () => {
        configModal.close();
      },
    });
  };

  handleRemoveAccount = (record: any) => {
    const { t } = this.props;
    const config = {
      title: t('account.management.modal.warning_delete_account.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 78, whiteSpace: 'pre-wrap' }}>
            <span>{t('account.management.modal.warning_delete_account.content')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger
              style={{ marginRight: 12, height: 40 }}
              onClick={() => {
                this.modalWarning.destroy();
                this.handleRemove();
              }}
            >
              {t('account.management.modal.warning_delete_account.title')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelDelete}>
              {t('account.management.modal.warning_turn_off_ip.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarning = modal.confirm(config);
  };

  renderTitle = () => {
    const { isCreate = true, isDetail, t } = this.props;
    if (isDetail) {
      return t('account.management.modal.detail.title');
    } else if (isCreate) {
      return t('account.management.modal.create_new_account.title');
    }
    return t('account.management.modal.edit_new_account.title');
  };

  render() {
    const { t, isCreate = true, form, limitIP, isDetail } = this.props;
    const { loading, dataUserSupport = [], activeUser } = this.state;

    return (
      <Spin spinning={loading}>
        <div className="formAddAccount">
          <div className="title-modal">
            <div>{this.renderTitle()}</div>
            <div className="title-modal" style={{ paddingBottom: 0, marginRight: 46 }}>
              {!isCreate && limitIP && !isDetail ? (
                <Button
                  style={{ marginTop: -10, fontSize: 15 }}
                  danger
                  ghost
                  onClick={this.handleTurnOffIPModal}
                >
                  {t('account.management.modal.edit.button.turn_of_ip_address_restriction')}
                </Button>
              ) : null}
              {!isCreate && !activeUser && !isDetail && (
                <Button
                  style={{ marginTop: -10, marginLeft: 10, fontSize: 15 }}
                  danger
                  ghost
                  onClick={this.handleRemoveAccount}
                >
                  <DeleteOutlinedIcon style={{ marginBottom: -4 }} fontSize="small" />
                  {t('account.management.modal.warning_remove.title')}
                </Button>
              )}
            </div>
          </div>
          <div className="title">
            <span>{t('account.management.modal_create.title.account_infomation')}</span>
            {/* <Tooltip title={t('account.management.modal_create.title.account_infomation.tooltip')}> */}
            {/* <InfoCircleFilled style={{ marginLeft: 5, color: 'rgba(0, 0, 0, 0.54)' }} /> */}
            {/* </Tooltip> */}
          </div>
          <Row gutter={[32, 32]}>
            {!isCreate || isDetail ? (
              <Col span={4} style={{ marginTop: 5 }}>
                <Form.Item
                  name="org_number"
                  label={t('account.management.table.column.account_id')}
                >
                  <InputBase previewSpan disabled />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={isCreate ? 24 : 20}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t(
                      'account.management.modal.create_new_account.field.account_name.required'
                    ),
                  },
                ]}
                name="name"
                label={t('account.management.modal.create_new_account.field.account_name')}
              >
                <InputBase
                  previewSpan
                  disabled={isDetail}
                  placeholder={t(
                    'account.management.modal.create_new_account.field.account_name.placeholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('account.management.modal.create_new_account.field.address.required'),
              },
            ]}
            name="address"
            label={t('account.management.modal.create_new_account.field.address')}
          >
            <InputBase
              previewSpan
              disabled={isDetail}
              placeholder={t(
                'account.management.modal.create_new_account.field.address.placeholder'
              )}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: t(
                  'account.management.modal.create_new_account.field.account_business_type.required'
                ),
              },
            ]}
            name="business_type"
            label={t('account.management.modal.create_new_account.field.account_business_type')}
          >
            <Select
              disabled={isDetail}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={
                isDetail
                  ? ''
                  : t(
                      'account.management.modal.create_new_account.field.account_business_type.placeholder'
                    )
              }
            >
              {this.renderDataType().map((el: any, index: number) => (
                <Select.Option value={el.value} key={index.toString()}>
                  {el.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[]}
            name="payment_term"
            label={t('account.management.modal.create_new_account.field.payment_terms')}
          >
            <InputBase
              previewSpan
              disabled={isDetail}
              placeholder={t(
                'account.management.modal.create_new_account.field.payment_terms.placeholder'
              )}
            />
          </Form.Item>
          <Row gutter={[32, 32]}>
            <Col span={12}>
              <Form.Item
                name="contract_start_date"
                label={t('account.management.modal.create_new_account.field.contract_start_date')}
              >
                <DatePicker
                  disabled={isDetail}
                  format="YYYY/MM/DD"
                  style={{ width: '100%', height: 42 }}
                  disabledDate={(date: any) => {
                    return (
                      form.getFieldValue('contract_end_date') &&
                      moment(date.startOf('day')).diff(
                        moment(form.getFieldValue('contract_end_date').endOf('day')),
                        'day'
                      ) >= 0
                    );
                  }}
                  placeholder={
                    isDetail
                      ? ''
                      : t(
                          'account.management.modal.create_new_account.field.contract_start_date.placeholder'
                        )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[]}
                name="contract_end_date"
                label={t('account.management.modal.create_new_account.field.contract_end_date')}
              >
                <DatePicker
                  disabled={isDetail}
                  disabledDate={(date: any) => {
                    return (
                      form.getFieldValue('contract_start_date') &&
                      moment(date.endOf('day')).diff(
                        moment(form.getFieldValue('contract_start_date').startOf('day')),
                        'day'
                      ) <= 0
                    );
                  }}
                  format="YYYY/MM/DD"
                  style={{ width: '100%', height: 42 }}
                  placeholder={
                    isDetail
                      ? ''
                      : t(
                          'account.management.modal.create_new_account.field.contract_end_date.placeholder'
                        )
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {!isCreate ? (
            <Form.Item shouldUpdate label={t('account.management.table.column.status.title')}>
              {(props) => {
                const checked = props.getFieldValue('is_active');
                const isDeleted = props.getFieldValue('deleted');
                if (isDeleted) {
                  return (
                    <span style={{ color: 'rgb(255, 59, 48)' }}>
                      {t('account.management.table.column.status.deleted')}
                    </span>
                  );
                }
                return (
                  <SwitchBox
                    disabled={isDetail}
                    onChange={(checked) => {
                      if (isDetail) {
                        return;
                      }
                      this.handleWarningDeActive(checked, props);
                    }}
                    style={{ width: 100, color: checked ? '' : '#ff3b30' }}
                    text={
                      checked
                        ? t('account.management.table.column.status.value.active')
                        : t('account.management.table.column.status.value.inactive')
                    }
                    size="small"
                    checked={checked}
                  />
                );
              }}
            </Form.Item>
          ) : null}
          <div className="title">
            <span>{t('account.management.modal_create.title.person_incharge_information')}</span>
            {/* <Tooltip
              title={t('account.management.modal_create.title.person_incharge_information_tooltip')}
            > */}
            {/* <InfoCircleFilled style={{ marginLeft: 5, color: 'rgba(0, 0, 0, 0.54)' }} /> */}
            {/* </Tooltip> */}
          </div>
          <Form.Item
            rules={[]}
            name="person_charge_name"
            label={t('account.management.modal.create_new_account.field.person_in_charge_name')}
          >
            <InputBase
              previewSpan
              disabled={isDetail}
              placeholder={t(
                'account.management.modal.create_new_account.field.person_in_charge_name.placeholder'
              )}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: false,
                message: t('user.management.modal.create_new_user.field.email.required'),
              },
              {
                validator: this.handleValidateEmail,
              },
            ]}
            name="person_charge_email"
            label={t('user.management.modal.create_new_user.field.email')}
          >
            <InputBase
              previewSpan
              disabled={isDetail}
              placeholder={t('user.management.modal.create_new_user.field.email.placeholder')}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                validator: this.handleValidatePhone,
              },
            ]}
            name="person_charge_phone"
            label={t('account.management.modal.create_new_account.field.phone_number')}
          >
            <InputBase
              previewSpan
              disabled={isDetail}
              maxLength={12}
              placeholder={t(
                'account.management.modal.create_new_account.field.phone_number.placeholder'
              )}
            />
          </Form.Item>
          <div className="title">
            <span>{t('account.management.modal_create.title.os_support_member')}</span>
            {/* <Tooltip title={t('account.management.modal_create.title.os_support_member_tooltip')}> */}
            {/* <InfoCircleFilled style={{ marginLeft: 5, color: 'rgba(0, 0, 0, 0.54)' }} /> */}
            {/* </Tooltip> */}
          </div>
          <Form.Item
            rules={[
              {
                required: false,
                message: t(
                  'account.management.modal.create_new_account.field.standby_os_support.required'
                ),
              },
            ]}
            name="sos_support_user"
            label={t('account.management.modal.create_new_account.field.standby_os_support')}
          >
            <Select
              allowClear
              disabled={isDetail}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={isDetail ? '' : t('account.detail.placeholder.type')}
            >
              {dataUserSupport &&
                dataUserSupport.map((el: any, index: number) => (
                  <Select.Option value={el._id} key={index.toString()}>
                    {`${el.last_name} ${el.first_name}`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </Spin>
    );
  }
}

export default withTranslation()(FormAddAccount);
