import SwitchBox from 'components/SwitchBox';
import TableBase from 'components/TableBase';
import moment from 'moment';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { IUser } from 'sagas/models/user';
import content from 'services/content';
import throwException from 'utils/throwException';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button } from 'antd';
import modal from 'antd/lib/modal';
import { showMessageSuccess } from 'utils';
import FormAdd from './FormAdd';
import ModalEdit from './ModalEdit';

// import ModalPublic from './ModalPublic';

interface IProps {
  t: any;
  user: IUser;
  onRef?: any;
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
  can_add_new_maintance: boolean;
  status: string;
}

class Maintance extends Component<IProps, IState> {
  refFormAdd: any;
  page_number: number;
  namesearch: any;
  modalWarning: any;
  modalWarningChangeStatus: any;
  modalEdit: any;
  modaPublic: any;
  constructor(props: IProps) {
    super(props);
    this.state = { data: [], loading: true, totalDocs: 0, can_add_new_maintance: true, status: '' };
    this.page_number = 1;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    content
      .list({
        page_size: 10,
        page: this.page_number,
        name: this.namesearch,
        content_type: 'MAINTANCE',
      })
      .then(({ data }) => {
        const { docs, totalDocs, can_add_new_maintance } = data.data;
        this.setState({
          data: docs,
          loading: false,
          totalDocs,
          can_add_new_maintance: can_add_new_maintance,
        });
      })
      .catch(this.handleError);
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  handleChangePublic = (checked: boolean, record: any, callback) => {
    this.handleCancelModal();
    const { t } = this.props;
    this.setState({ loading: true });
    content
      .public(record._id)
      .then(() => {
        this.getData();
        if (callback) callback();
        if (checked) {
          showMessageSuccess(t('content.management.maintenance.modal.public_success'));
        } else {
          showMessageSuccess(t('content.management.notice.modal.unpublic_success'));
        }
      })
      .catch(this.handleError);
  };

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };

  renderActionStatus = (id, status) => {
    const { t } = this.props;
    switch (status) {
      case 'READY_TO_NOTIFYRM':
        return (
          <div>
            <div style={{ color: '#007AFF' }}>{t('content.status.ready_to_notify.title')}</div>
          </div>
        );
      case 'NOTIFY':
        return (
          <div>
            <div style={{ color: '#007AFF' }}>{t('content.status.notifi.title')}</div>

            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.handleWarningChangeStatus(id, status);
                }}
              >
                {t('content.management.title.cancel.cancelScheduleBtn')}
              </Button>
            </div>
          </div>
        );
      case 'IN_PROGRESS':
        return (
          <div>
            <div style={{ color: '#FF9500' }}>{t('content.status.in_progress.title')}</div>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.handleWarningChangeStatus(id, status);
                }}
              >
                {t('content.management.mainternace.finishBtn')}
              </Button>
            </div>
          </div>
        );
      case 'CANCELLED':
        return <div style={{ color: 'red' }}>{t('content.status.cancel.title')}</div>;
      case 'COMPLETED':
        return <div style={{ color: '#34C759' }}>{t('content.status.completed.title')}</div>;
      default:
        break;
    }
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('content.management.maintenance.column.title'),
        dataIndex: 'title',
        width: 450,
      },
      {
        title: '',
        dataIndex: 'published',
        width: 200,

        render: (v: boolean, record: any) => {
          if (record.status !== 'READY_TO_NOTIFYRM') return <div></div>;
          return (
            <SwitchBox
              checked={v}
              onChange={(v) => {
                this.handleWarningPublic(v, record);
              }}
              size="small"
              text={
                v
                  ? t('content.management.article.column.notified')
                  : t('content.management.article.column.unnotified')
              }
            />
          );
        },
      },
      {
        title: t('content.management.maintenance.column.status'),
        dataIndex: 'status',
        align: 'center',
        width: 200,
        render: (v: string, record: any) => <div>{this.renderActionStatus(record._id, v)}</div>,
      },
      {
        title: t('content.management.maintenance.column.start_date'),
        dataIndex: 'start_schedule',
        width: 200,
        render: (v) => moment(v).format('YYYY/MM/DD HH:mm'),
      },
      {
        title: t('content.management.maintenance.column.end_date'),
        dataIndex: 'end_schedule',
        width: 200,
        render: (v) => (v ? moment(v).format('YYYY/MM/DD HH:mm') : ''),
      },
      {
        title: '',
        width: 140,
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, record: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <ModeEditOutlineOutlinedIcon
                fontSize="small"
                onClick={() => {
                  this.handleEdit(record);
                  this.setState({ status: record.status });
                }}
                style={{
                  display: record.status === 'READY_TO_NOTIFYRM' ? 'block' : 'none',
                  userSelect: 'none',
                  cursor: 'pointer',
                  opacity: 1,
                }}
              />

              <DeleteOutlineIcon
                fontSize="small"
                style={{
                  userSelect: 'none',
                  display: record.status === 'READY_TO_NOTIFYRM' ? 'block' : 'none',
                  cursor: 'pointer',
                  marginLeft: 30,
                  opacity: 1,
                }}
                onClick={() => this.handleWarningDelete(record)}
              />
              <DescriptionIcon
                fontSize="small"
                onClick={() => {
                  this.handleEdit(record);
                  this.setState({ status: record.status });
                }}
                style={{
                  display: record.status !== 'READY_TO_NOTIFYRM' ? 'block' : 'none',
                  userSelect: 'none',
                  cursor: 'pointer',
                  opacity: 1,
                }}
              />
            </div>
          );
        },
      },
    ];
    return columns;
  };

  handleChangeStatus = (id: string, callback?: any) => {
    this.setState({ loading: true });
    this.handleCancelChangeStatusModal();
    content
      .changeStatusMaintance(id)
      .then(() => {
        if (callback) callback();
        this.getData();
      })
      .catch(this.handleError)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCancelChangeStatusModal = () => {
    this.modalWarningChangeStatus?.destroy?.();
  };

  handleRemove = (record: any) => {
    if (record.status !== 'READY_TO_NOTIFYRM') return;
    const { t } = this.props;
    this.setState({ loading: true });
    this.handleCancelModal();
    content
      .delete(record._id)
      .then(() => {
        this.page_number = 1;
        this.namesearch = '';
        this.getData();
        showMessageSuccess(t('content.management.article.delete_mantenance.success'));
      })
      .catch(this.handleError);
  };

  handleCancelModal = () => {
    this.modalWarning?.destroy?.();
  };

  handleModalPiblic = (record: any) => {
    this.modaPublic?.handleVisible?.(record);
  };

  handleWarningPublic = (_checked, record: any, callback?: any) => {
    const { t } = this.props;
    const startTime = moment(new Date(moment(Date.now()).format('YYYY/MM/DD HH:mm')));
    const endTime = moment(new Date(moment(record.start_schedule).format('YYYY/MM/DD HH:mm')));
    const diffTime = endTime.diff(startTime);
    const config = {
      title: t('content.management.maintenance.modal.warning.public.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>{t('content.management.maintenance.modal.warning.public.content')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger={record.published}
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleChangePublic(_checked, record, callback)}
            >
              {t('content.management.maintenance.modal.warning.public.okBtn')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelModal}>
              {t('account.management.modal.warning_turn_off_ip.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    const configNotPublic = {
      title: t('content.management.maintenance.public.error.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>{t('content.management.maintenance.public.error.body')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger={record.published}
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleCancelModal()}
            >
              {t('content.management.maintenance.public.error.buttonOk')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarning = diffTime > 0 ? modal.confirm(config) : modal.confirm(configNotPublic);
  };

  handleWarningDelete = (record: any) => {
    const { t } = this.props;
    const is_draft = record.status === 'READY_TO_NOTIFYRM';
    if (!is_draft) return;
    const config = {
      title: t('content.management.article.modal.warning.delete_mantenance.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>{t('content.management.article.modal.warning.delete_mantenance.content')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleRemove(record)}
            >
              {t('content.management.article.modal.warning.delete_notice.button_delete')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelModal}>
              {t('account.management.modal.warning_turn_off_ip.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarning = modal.confirm(config);
  };

  handleWarningChangeStatus = (id: string, status: string) => {
    const { t } = this.props;
    const config = {
      title:
        status === 'NOTIFY'
          ? t('content.management.title.cancel.scheduled')
          : t('content.management.title.finish.scheduled'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>
              {status === 'NOTIFY'
                ? t('content.management.body.cancel.scheduled')
                : t('content.management.body.finish.scheduled')}
            </span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              // type="error"
              danger
              style={{
                marginRight: 12,
                height: 40,
                backgroundColor: status === 'NOTIFY' ? '#FF3B30' : '#18BCF3',
                color: '#FFFFFF',
              }}
              onClick={() =>
                this.handleChangeStatus(id, () => {
                  if (status === 'NOTIFY') {
                    showMessageSuccess(t('content.management.title.cancel.scheduled.success'));
                  } else {
                    showMessageSuccess(t('content.management.title.finish.success'));
                  }
                })
              }
            >
              {status === 'NOTIFY'
                ? t('content.management.title.cancel.scheduled.button')
                : t('other_setting.admin_system.title.button.finish')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelChangeStatusModal}>
              {t('content.management.title.cancel.scheduled.cancelBtn')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarningChangeStatus = modal.confirm(config);
  };

  handleOkAdd = (values, callback) => {
    const { t } = this.props;
    this.refFormAdd?.handleOk(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      if (success) {
        this.getData();
        showMessageSuccess(t('content.management.maintenance.add_success'));
      }
    });
  };

  handleEdit = (record) => {
    this.modalEdit?.handleVisible?.(record);
  };

  handlePublic = (record: any, callback: any) => {
    this.handleWarningPublic(true, record, callback);
  };

  render() {
    const { data, loading, totalDocs } = this.state;
    const { onRef } = this.props;
    if (onRef) onRef(this);

    return (
      <Fragment>
        <TableBase
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          onOkFormAdd={this.handleOkAdd}
          // handleSearch={this.handleSearch}
          loading={loading}
          lableKeyI18n="content.management.new_maintance_btn"
          widthModalAdd={791}
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
          allowAdd={this.state.can_add_new_maintance}
          formAdd={(props) => (
            <FormAdd
              isCreate
              onRef={(ref) => {
                this.refFormAdd = ref;
              }}
              {...props}
            />
          )}
        />
        <ModalEdit
          onRef={(ref) => {
            this.modalEdit = ref;
          }}
          reloadData={this.getData}
          status={this.state.status}
        />
      </Fragment>
    );
  }
}

export default connect(({ user }: { user: { user: IUser } }) => ({ user: user.user }))(Maintance);
