import TableBase from 'components/TableBase';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IUser } from 'sagas/models/user';
import BreadcrumbBase from 'components/Breadcrumb';
import throwException from 'utils/throwException';
import account from 'services/account';

import moment from 'moment';
import FormAddAccount from './FormAdd';
import { showMessageSuccess } from 'utils';
import auth from 'services/auth';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ModalEdit from './ModalEdit';
import ModalHistory from './ModalHistory';
import { find, get } from 'lodash';

interface IProps {
  t: any;
  user: IUser;
  history: any;
  pathname: string;
}

interface IState {
  loading: boolean;
  data: any[];
  totalDocs: number;
}

class AccountManagement extends Component<IProps, IState> {
  page_number: number;
  namesearch: string;
  modalWarning: any;
  refFormAdd: any;
  modalEdit: any;
  modalHistory: any;

  constructor(props: IProps) {
    super(props);
    this.page_number = 1;
    this.namesearch = '';
    this.state = { loading: true, data: [], totalDocs: 0 };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
    }
    account
      .accounts({ page_size: 10, page: this.page_number, name: this.namesearch })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  renderDataType = () => {
    const { t } = this.props;
    return [
      { value: 'EDUCATIONAL', title: t('title.type.account.enterprise') },
      { value: 'ENTERPRISE', title: t('title.type.account.education') },
      { value: 'MEDICAL', title: t('title.type.account.medical') },
    ];
  };

  convertStringLimit = (val: string): string => {
    if (!val) {
      return '';
    }
    return val.length > 30 ? `${val.substring(0, 30)}...` : val;
  };

  renderColumns = (): any[] => {
    const { t, user, history } = this.props;
    const columns = [
      {
        title: t('account.management.table.column.account_id'),
        dataIndex: 'org_number',
        key: 'org_number',
        width: 85,
        render: (v) => {
          return <div style={{ minWidth: 85 }}>{v}</div>;
        },
      },
      {
        title: t('account.management.table.column.name'),
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (v, record) => (
          <div
            onClick={() => {
              this.modalEdit?.handleVisible?.(record, true);
            }}
            className="text-split"
            style={{
              color: '#007AFF',
              cursor: 'pointer',
              width: (200 / 1170) * window.innerWidth,
            }}
          >
            {v}
          </div>
        ),
      },
      {
        title: t('account.management.table.column.status'),
        dataIndex: 'is_active',
        key: 'is_active',
        width: 150,
        render: (v: boolean, record) => {
          const { t } = this.props;
          if (record.deleted) {
            return (
              <span style={{ color: '#FF3B30', fontSize: 17 }}>
                {t('account.management.table.column.status.deleted')}
              </span>
            );
          }
          if (v) {
            return (
              <span style={{ color: '#34C759', fontSize: 17 }}>
                {t('account.management.table.column.status.value.active')}
              </span>
            );
          }
          return (
            <span style={{ color: '#FF3B30', fontSize: 17 }}>
              {t('account.management.table.column.status.value.inactive')}
            </span>
          );
        },
      },
      {
        title: t('account.management.table.column.business_type'),
        dataIndex: 'business_type',
        key: 'business_type',
        width: 160,
        render: (v) => {
          let getCategoryNotifi = find(this.renderDataType(), (item) => {
            return get(item, 'value', '') === v;
          });
          return <div>{get(getCategoryNotifi, 'title')}</div>;
        },
      },
      {
        title: t('account.management.table.column.personal_incharge'),
        dataIndex: 'person_charge_name',
        key: 'person_charge_name',
        width: 220,
        render: (v) => (
          <span
            style={{
              wordBreak: 'break-word',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {this.convertStringLimit(v)}
          </span>
        ),
      },
      {
        title: t('account.management.table.column.contract_start_date'),
        dataIndex: 'contract_start_date',
        key: 'contract_start_date',
        width: 120,
        render: (_v) => (_v ? moment(_v).format('YYYY/MM/DD') : ''),
      },
      {
        title: t('account.management.table.column.contract_end_date'),
        dataIndex: 'contract_end_date',
        key: 'contract_end_date',
        width: 120,
        render: (_v) => (_v ? moment(_v).format('YYYY/MM/DD') : ''),
      },
      {
        title: '',
        width: 115,
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, record: any) => {
          const { email, deleted } = record;
          if (email === user.email) return null;
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ModeEditOutlineOutlinedIcon
                onClick={() => {
                  this.getData();
                  if (!deleted) {
                    this.handleEdit(record);
                  }
                }}
                fontSize="small"
                style={{ cursor: deleted ? 'not-allowed' : 'pointer', opacity: deleted ? 0.3 : 1 }}
              />
              <HistoryOutlinedIcon
                onClick={() => this.modalHistory?.handleVisible?.(record)}
                style={{ marginLeft: 28, cursor: 'pointer' }}
                fontSize="small"
              />
              <PeopleAltOutlinedIcon
                onClick={() => {
                  if (!deleted) {
                    history.push(`account-management/${record._id}`);
                  }
                }}
                fontSize="small"
                style={{
                  marginLeft: 28,
                  cursor: deleted ? 'not-allowed' : 'pointer',
                  opacity: deleted ? 0.3 : 1,
                }}
              />
            </div>
          );
        },
      },
    ];
    return columns;
  };

  handleEdit = (record) => {
    this.modalEdit?.handleVisible?.(record);
  };

  handleOkAdd = (values, callback) => {
    const { t } = this.props;

    this.refFormAdd?.handleOk(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      if (success) {
        this.page_number = 1;
        this.namesearch = '';
        this.getData();
        showMessageSuccess(t('account.management.create_account.success'));
      }
    });
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  handleDelete = (record: any, callback: any) => {
    const { t } = this.props;
    this.modalWarning?.destroy();
    auth
      .removeUser(record._id)
      .then(() => {
        if (typeof callback === 'function') callback();
        this.getData();
        showMessageSuccess(t('user.management.delete_user.success'));
      })
      .catch(throwException);
  };

  handleCancelDelete = () => {
    this.modalWarning?.destroy();
  };

  render() {
    const { pathname } = this.props;
    const { data, totalDocs, loading } = this.state;
    const colums = this.renderColumns();
    return (
      <div>
        <BreadcrumbBase pathname={pathname} />
        <ModalEdit
          onRef={(ref) => {
            this.modalEdit = ref;
          }}
          reloadData={this.getData}
        />
        <ModalHistory
          onRef={(ref) => {
            this.modalHistory = ref;
          }}
        />
        <TableBase
          allowAdd={true}
          lableKeyI18n="account.management.button.add"
          widthModalAdd={791}
          rowKey="_id"
          dataSource={data}
          columns={colums}
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          handleSearch={this.handleSearch}
          onOkFormAdd={this.handleOkAdd}
          formAdd={(props) => (
            <FormAddAccount
              scrollToFirstError={true}
              onRef={(ref) => {
                this.refFormAdd = ref;
              }}
              {...props}
            />
          )}
          loading={loading}
        />
      </div>
    );
  }
}

export default connect(({ user }: { user: { user: IUser } }) => ({ user: user.user }))(
  withTranslation()(AccountManagement)
);
